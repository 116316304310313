@use "@material/icon-button/styles";
@use "@material/top-app-bar/mdc-top-app-bar";
@use "@material/top-app-bar/mixins";
@use "@material/button/styles" as button-styles;
@use "@material/button";
@use "@material/card";
@use "@material/list/mdc-list";
@use "@material/menu-surface/mdc-menu-surface";
@use "@material/menu/mdc-menu";
@use "@material/floating-label/mdc-floating-label";
@use "@material/line-ripple/mdc-line-ripple";
@use "@material/notched-outline/mdc-notched-outline";
@use "@material/textfield";
@use "@material/typography/mdc-typography";
@use "@material/tooltip/styles" as tooltip-styles;
@use "@material/select/styles" as select-styles;

@include textfield.core-styles;
@include card.core-styles;

$layout-breakpoint-small: 960px;

body {
  margin: 0;
  padding: 0;
}

#map {
  width: 100%;
  height: calc(100vh - 64px);
}

.mdc-top-app-bar {
  @include mixins.fill-color( #fff );
}
//
//.top-app-bar__naviagtion-buttons {
//  background-color: rgba(255, 255, 255, 0.5);
//}

.header-background {
  //background: linear-gradient(0deg, rgba(255, 255, 0, 0.7) 0%, rgba(0, 0, 255, 0.7) 100%);
  background: linear-gradient(0deg, rgba(255, 255, 0, 0.1) 0%, rgba(255, 255, 0, 0.8) 49%, rgba(0, 0, 255, 0.8) 50%, rgba(0, 0, 255, 0.1) 100%);
}

.mdc-button-header {
  @include button.ink-color(#222);
  @include button.outline-color(#222);

  margin-top: 2em;
  margin-right: 15px;

  @media (max-width: $layout-breakpoint-small) {
    margin-right: 0;
  }
}

.popup-card-content {
  padding: 16px;
}

.popup-card {
  height: auto;
  width: 300px;
}

.region-card {
  width: 300px;
  margin-top: 25px;
}

.popup {
  position: relative;
  left: -150px;
  top: 15px;
}

.container {
  width: 1000px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 25px auto auto;

  @media (max-width: $layout-breakpoint-small) {
    width: 100%;
    justify-content: space-around;
  }
}

.region-card__media {
  background-image: url("https://storage.googleapis.com/map_media/images/ukraine.jpg");
}

.mdc-card-wrapper__text-section {
  padding-left: 16px;
  padding-right: 16px;
}

.mdc-card-wrapper__text-section:last-child {
  padding-bottom: 16px;
}

.mdc-card-wrapper__text-section:first-child,
.mdc-card__media + .mdc-card-wrapper__text-section {
  padding-top: 16px;
}

.region-card__title {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit;
}

.region-card__subhead {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  text-decoration: inherit;
  text-transform: inherit;
}

.timeline {
  position: relative;
  margin: 0 auto;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 1px;
  background-color: #d7e3ec;
  top: 0;
  bottom: 0;
  margin-left: -3px;

  @media screen and (max-width: 600px) {
    margin-left: 45px;
    //left: 31px;
    //max-width: 500px;
  }
}

@mixin container-timeline {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;

  @media screen and (max-width: 600px) {
    padding-left: 70px;
    //padding-right: 25px;
  }
}

.container-timeline {
  @include container-timeline;
}

.container-timeline-secondary {
  @include container-timeline;

  @media screen and (max-width: 600px) {
    max-width: 450px;
  }
}

.content-timeline-secondary {
  border: 0;
  padding: 0;
  margin: 0;
}

.container-timeline::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  border: 1px solid #d7e3ec;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.container-timeline-alert::after {
  background-color: #c7362c;
}

.container-timeline-cancel::after {
  background-color: #6ba443;
}

.container-timeline-warning::after {
  background-color: #fac72e;
}

.right-timeline::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent #d7e3ec transparent transparent;

  @media screen and (max-width: 600px) {
    left: 60px;
    border: medium solid #d7e3ec;
    border-width: 10px 10px 10px 0;
    border-color: transparent #d7e3ec transparent transparent;
  }
}

.right-timeline::after {
  left: -16px;

  @media screen and (max-width: 600px) {
    left: 38px;
    width: 15px;
    height: 15px;
    top: 22px;
  }
}

.content-timeline {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
  border: 1px solid #d7e3ec;
}

.popup::after {
  content: " ";
  border: 1px solid #e0e0e0;
  position: absolute;
  left: 140px;
  top: -20px;
  border-color: transparent transparent #e0e0e0 transparent;
  border-width: 10px 10px 10px 10px;
  width: 0;
  height: 0;
}

.time-pick {
  margin-top: 2em;
  padding: 8px;
  background-color: transparent;
  border: 0;
  font-family: var(--mdc-text-button-label-text-font, var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif)));
  font-size: var(--mdc-text-button-label-text-size, var(--mdc-typography-button-font-size, 0.875rem));
}

.time-pick:active {
  border: 0;
}

.time-pick:focus {
  border: 0;
}

.filters {
  width: 1000px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 35px auto auto;

  @media (max-width: $layout-breakpoint-small) {
    width: 95%;
  }
}

.region-search-field {
  width: 75%;
}

#menu-button {
  color: blue;
}

.button-desktop {
  @media (max-width: $layout-breakpoint-small) {
    display: none;
  }
}

.button-mobile {
  display: none;

  @media (max-width: $layout-breakpoint-small) {
    display: inline;
  }
}

.stata {
  width: 70%;
  margin: auto;

  @media (max-width: $layout-breakpoint-small) {
    width: 95%;
  }
}

.tooltip-source {
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.mdc-tooltip__surface > a {
  color: #fff;
}

.demo-width-class {
  width: 400px;
}
